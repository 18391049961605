import './index.scss'
let cached_user_id = localStorage.getItem('cached_user_id');
if(cached_user_id !=undefined) {
    $(".cookie").hide()
}else {
    $(".cookie").show()
}

function setCookie(name, value, days) {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}
setCookie("test_cookie", "abc", 1);

// 1. 获取页面上的按钮元素
const getAndStoreCookieButton = document.getElementById('getAndStoreCookieButton');

// 2. 为按钮添加点击事件监听器
getAndStoreCookieButton.addEventListener('click', function () {
    // 假设要获取名为 'user_id' 的Cookie，你可以替换成实际想获取的Cookie名称
    let userId = document.cookie;
    if (userId) {
        // 4. 将获取到的Cookie值存储到localStorage中，这里键设为 'cached_user_id'
        localStorage.setItem('cached_user_id', userId);
       $(".cookie").hide();
    }
});